import React from "react";
import { Helmet } from "react-helmet";
import {
  // Video,
  Locations,
  Artists,
  About,
  Services,
} from "../components";
import TextSection from "./TextSection";
import HeroImage from "./HeroImage";

function Home() {
  return (
    <>
      <div className="position-relative">
        <HeroImage />
        <TextSection />
        <Artists />
        <Services />
        <Locations />
        {/* <Video /> */}
        <About />
        <Helmet>
          <title>Sacred Art Tattoo Aiea</title>
        </Helmet>
      </div>
    </>
  );
}

export default Home;
